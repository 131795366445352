<div class="h-full" *transloco="let t">
  <div class="flex h-full">
    <div class="h-full" style="width: 35rem">
      <app-tracked-asin-selection
        [marketplace]="(selectedAccountMarketplace$ | async)?.marketplace"
        (hover)="onAsinSelectorHover($event)"
        (leave)="onAsinSelectorLeave()"
        [datavizScroll]="datavizScroll"
        [updateScrollEvent]="updateScroll.asObservable()"
      ></app-tracked-asin-selection>
    </div>
    <div class="dataviz-wrapper ml-3 flex flex-col overflow-auto p-3">
      <div class="dataviz-header">
        <div class="flex items-center justify-between">
          <div class="flex">
            <app-switch-button
              class="rank-switch ml-3"
              [options]="[true, false]"
              [buttonTexts]="['common.table', 'common.chart']"
              [buttonTooltips]="['', '']"
              [selected]="viewModeDefault"
              (buttonClicked)="setTableView($event)"
            ></app-switch-button>
          </div>

          <div class="flex">
            <app-switch-button
              class="rank-switch ml-3"
              [options]="RANK_OPTIONS_ARRAY"
              [buttonTexts]="RANK_OPTIONS_ARRAY"
              [buttonTooltips]="RANK_OPTIONS_DESC"
              [selected]="rankOption()"
              (buttonClicked)="selectRankOption($event)"
            ></app-switch-button>

            @if ((tableView$ | async) === false && (dateAggregation$ | async)) {
              <app-date-aggreation-switch-button
                class="aggregation-switch ml-3"
                [options]="dateAggregations"
                [selected]="(dateAggregation$ | async)!"
                (dateAggSelected)="selectDateAggregation($event)"
              ></app-date-aggreation-switch-button>
            }

            @if (tableView$ | async) {
              <app-export-button class="ml-3" (export)="exportData()" />
            }
          </div>
        </div>

        @if (rankOption() === RankOption.BOTH) {
          <div class="both-opt-legend ml-4 mt-2 flex items-center">
            <div class="org"></div>
            <div class="mr-3">{{ t("common.organic") }}</div>
            <div class="sp"></div>
            {{ t("common.sponsored") }}
          </div>
        }
      </div>
      <div class="dataviz-content mt-2 w-full overflow-auto" #datavizScroll (scroll)="updateScroll.next($event)">
        @if ((tableView$ | async) === true) {
          <app-keyword-tracking-timeline-table
            [accountMarketplace]="(selectedAccountMarketplace$ | async)!"
            [rankOption]="rankOption()!"
          ></app-keyword-tracking-timeline-table>
        }
        @if ((tableView$ | async) === false) {
          <div class="flex h-full items-center">
            <canvas
              baseChart
              class="chart"
              type="line"
              height="100%"
              [labels]="dataset.labels"
              [options]="dataset.lineChartOptions"
              [datasets]="dataset.chartDataSet"
            >
            </canvas>
          </div>
        }
      </div>
    </div>
  </div>
</div>
