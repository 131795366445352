import { Component, Input, OnInit, Pipe, PipeTransform } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { KeywordTrackerService } from "../../../services/keyword-tracker.service";
import moment from "moment-timezone";
import { AccountMarketplace, Product } from "@front/m19-api-client";
import { AuthService, RankOption } from "@front/m19-services";
import { Marketplaces } from "@front/m19-models";
import { AsyncPipe, DecimalPipe, NgClass, NgTemplateOutlet } from "@angular/common";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslocoDirective } from "@jsverse/transloco";

export type BucketizedRanking = {
  rank: number;
  bucket: number | undefined;
};

export type RankingDataForDate = {
  organic: BucketizedRanking;
  sponsored: BucketizedRanking;
  global: BucketizedRanking;
};

export type RankingDataRow = {
  asin: string;
  timeline: {
    [date: string]: RankingDataForDate;
  };
  inCatalog?: boolean;
  childrenRow?: RankingDataRow[];
  parent?: boolean;
};

type ProductWithInfo = Product & { belongToCatalog: boolean };

@Pipe({ name: "getRank", standalone: true })
export class GetRankPipe<T, U extends { organic: T; sponsored: T; global: T }> implements PipeTransform {
  transform(input: U, option: RankOption): T | undefined {
    if (!input || option == RankOption.BOTH) {
      return undefined;
    }
    switch (option) {
      case RankOption.ORGANIC:
        return input.organic;
      case RankOption.SPONSORED:
        return input.sponsored;
      case RankOption.GLOBAL:
        return input.global;
    }
  }
}

@UntilDestroy()
@Component({
  selector: "app-keyword-tracking-timeline-table",
  templateUrl: "./keyword-tracking-timeline-table.component.html",
  styleUrls: ["./keyword-tracking-timeline-table.component.scss"],
  standalone: true,
  imports: [AsyncPipe, GetRankPipe, DecimalPipe, NgClass, MatTooltip, TranslocoDirective, NgTemplateOutlet],
})
export class KeywordTrackingTimelineTableComponent implements OnInit {
  // component inputs
  @Input()
  searchTerm!: string;
  @Input()
  accountMarketplace!: AccountMarketplace;
  @Input()
  rankOption!: RankOption;

  // const values
  readonly RankOption = RankOption;

  // component state
  locale?: string;
  products: Map<string, ProductWithInfo> = new Map();
  timezone?: string;

  // table data
  data$ = this.keywordTrackerService.asinRankRowData$;
  dates$: Observable<string[]> = this.keywordTrackerService.dates$;
  groupByParent$: Observable<boolean> = this.keywordTrackerService.groupByParent$;
  graphAsins$ = this.keywordTrackerService.graphAsin$;
  hiddenAsins$ = this.keywordTrackerService.hiddenAsins$;

  constructor(
    private keywordTrackerService: KeywordTrackerService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.locale = user.locale;
    });
  }

  formatDate(date: string, withYear = false) {
    const toTimestamp = Date.parse(date);
    const format = withYear ? "MM/DD/Y" : "MM/D";
    return moment.utc(toTimestamp).tz(Marketplaces[this.accountMarketplace.marketplace].timeZone).format(format);
  }

  protected readonly moment = moment;
  protected readonly Marketplaces = Marketplaces;
}
