import { AgGridAngular } from "@ag-grid-community/angular";
import {
  ColDef,
  FirstDataRenderedEvent,
  GetContextMenuItemsParams,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { Clipboard } from "@angular/cdk/clipboard";
import { AsyncPipe, CurrencyPipe } from "@angular/common";
import { Component, OnInit, signal, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import {
  AccountMarketplace,
  AccountState,
  Currency,
  Product,
  ProductTrackerConfig,
  SearchTermAsinRank,
  User,
} from "@front/m19-api-client";
import { getBasicGridOptions } from "@front/m19-grid-config";
import { Marketplaces, SegmentConfigType, SegmentEx } from "@front/m19-models";
import { AccountSelectionService, AuthService, KeywordTrackingService, SegmentService } from "@front/m19-services";
import { IButtonComponent, IMultiSelectComponent, ISelectComponent, Option } from "@front/m19-ui";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";
import { KeywordRankingOverlayComponent } from "@m19-board/overlay/keyword-ranking-overlay.component";
import { ExportButtonComponent } from "@m19-board/shared/ui/export-buttons/export-button.component";
import { SpinnerComponent } from "@m19-board/spinner/spinner.component";
import { keywordRankingAvailableFor } from "@m19-board/tracking/KeywordRankingAvailability";
import { ICON_ADD, ICON_CHART_LINE, ICON_LIST } from "@m19-board/utils/iconsLabels";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";
import { combineLatest, Observable, switchMap, tap } from "rxjs";
import { actionColumnProperties, ImageColumn } from "../../grid-config/grid-columns";
import { ACTIONS_COL_ID, exportGridCsv, getCsvFileName, IMAGE_COL_ID } from "../../grid-config/grid-config";
import {
  ActionButton,
  ActionButtonsComponent,
} from "../../insights/advertising-stats/action-buttons/action-buttons.component";
import { AsinLinkComponent } from "../../product-view/asin-link.component";
import { ProductThumbnailComponent } from "../../product-view/product-thumbnail.component";
import { KeywordTrackerService } from "../../services/keyword-tracker.service";
import { ProductTrackerData, ProductTrackerService } from "../../services/product-tracker.service";
import { ReviewScoreComponent } from "../../tracking/review-score/review-score.component";
import { DisplayGroup, DisplayGroupsMap } from "../../utils/displayGroups";

export enum ProductTrackerTableColumns {
  Brand = "brand",
  MainCategory = "mainCategory",
  MainCategoryBSR = "mainCategoryBSR",
  SubCategory = "subCategory",
  SubCategoryBSR = "subCategoryBSR",
  Price = "price",
  Reviews = "reviews",
  Actions = "actions",
}

enum CatalogFilter {
  All = "All Products",
  Catalog = "Only from Catalog",
  NotInCatalog = "Not in Catalog",
}

export type ProductTrackerRow = Product & {
  trackedSearchTerms: string[];
  inCatalog: boolean;
  parentAsin?: string;
};

export interface ProductMetric<T> {
  title: string;
  field: string;
  tooltip: string;
  value: (product: Product) => T;
  formattedValue: (product: Product) => string;
  numeric?: boolean;
}

function productMetric<K extends keyof Product>(
  field: K,
  title: string,
  tooltip?: string,
  numeric?: boolean,
): ProductMetric<Product[K]> {
  return {
    title,
    field,
    tooltip: tooltip ?? "",
    value: (p) => p[field],
    formattedValue: (p) => p[field]?.toString() ?? "",
    numeric,
  };
}

export const SimpleProductMetrics = {
  [ProductTrackerTableColumns.Brand]: productMetric("brand", "common.brand"),
  [ProductTrackerTableColumns.MainCategory]: productMetric("displayGroup", "product-tracker.main_category"),
  [ProductTrackerTableColumns.MainCategoryBSR]: productMetric(
    "displayGroupRank",
    "product-tracker.main_category_bsr",
    "Main Category Best Seller Rank",
    true,
  ),
  [ProductTrackerTableColumns.SubCategory]: productMetric("categoryName", "product-tracker.sub_category"),
  [ProductTrackerTableColumns.SubCategoryBSR]: productMetric(
    "categoryRank",
    "product-tracker.sub_category_bsr",
    "Sub Category Best Seller Rank",
    true,
  ),
  [ProductTrackerTableColumns.Reviews]: productMetric("reviews", "product-tracker.number_of_reviews", undefined, true),
};

export enum ProductTrackerPageQueryParams {
  segment = "segment",
  asin = "trackedAsin",
}

export type TrackedSearchTerm = {
  searchTerm: string;
  timeline: SearchTermAsinRank[];
};

@UntilDestroy()
@Component({
  selector: "app-product-tracker",
  templateUrl: "./product-tracker.component.html",
  styleUrls: ["./product-tracker.component.scss"],
  standalone: true,
  imports: [
    KeywordRankingOverlayComponent,
    TranslocoDirective,
    ISelectComponent,
    IMultiSelectComponent,
    SpinnerComponent,
    IButtonComponent,
    AsyncPipe,
    ExportButtonComponent,
    AgGridAngular,
  ],
})
export class ProductTrackerComponent implements OnInit {
  readonly catalogFilterDdOptions: Option<CatalogFilter>[] = [
    {
      label: this.translocoService.translate("product-tracker.all_products"),
      value: CatalogFilter.All,
    },
    {
      label: this.translocoService.translate("product-tracker.only_from_catalog"),
      value: CatalogFilter.Catalog,
    },
    {
      label: this.translocoService.translate("product-tracker.not_in_catalog"),
      value: CatalogFilter.NotInCatalog,
    },
  ];
  catalogFilterSelected = signal<Option<CatalogFilter>>(this.catalogFilterDdOptions[0]);

  readonly PRODUCT_TRACKER_GRID_KEY = "productTrackerGrid";
  readonly ICON_ADD = ICON_ADD;

  data?: ProductTrackerRow[];
  dataByParentAsin = new Map<string, ProductTrackerRow>();

  currency?: Currency;
  locale?: string;
  accountMarketplace?: AccountMarketplace;
  keywordTrackingSupported = false;
  filter = "";

  segmentOptions: Option<SegmentEx>[] = [];
  selectedSegments = signal<Option<SegmentEx>[]>([]);

  productTrackerConfig: ProductTrackerConfig[] = [];
  asinInitValue?: string;

  isReadOnly$: Observable<boolean> = this.accountSelection.readOnlyMode$;
  loading = false;
  isGroupedByParent = false;
  readonly productTrackerUrl = "/product-center/product-tracker";

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  private colDefs: ColDef[] = [
    {
      ...ImageColumn,
      headerName: this.translocoService.translate("catalog-page.image", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("catalog-page.image"),
      pinned: undefined,
      cellRendererSelector: (params) => {
        const groupByParent = params.node?.field === "parentAsin";
        const asin = groupByParent ? params.node.key : params.value;
        if (!asin || asin === "") return undefined;
        return {
          component: ProductThumbnailComponent,
          params: {
            asin: asin,
            marketplace: this.accountMarketplace?.marketplace,
            smallImg: true,
          },
        };
      },
    },
    {
      field: "asin",
      headerName: this.translocoService.translate("common.asin", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("common.asin"),
      filter: "agTextColumnFilter",
      cellRendererSelector: (params: ICellRendererParams<ProductTrackerRow>) => {
        if (!params.node.group) {
          return {
            component: AsinLinkComponent,
            params: { asin: params.data?.asin, marketplace: this.accountMarketplace?.marketplace },
          };
        }
        return undefined;
      },
    },
    {
      headerName: this.translocoService.translate("product360.parent_asin", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("product360.parent_asin"),
      field: "parentAsin",
      hide: true,
      enableRowGroup: true,
    },
    {
      headerName: this.translocoService.translate("common.title", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("common.title"),
      field: "title",
      filter: "agTextColumnFilter",
      tooltipField: "title",
      width: 400,
      valueFormatter: (params: ValueFormatterParams<ProductTrackerRow>) => {
        if (params.node?.field === "parentAsin") return this.dataByParentAsin.get(params.node.key!)?.title;
        return params.value;
      },
    },
    {
      headerName: this.translocoService.translate("product-tracker.number_of_tracked_keywords", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("product-tracker.number_of_tracked_keywords"),
      field: "trackedSearchTerms",
      colId: "trackedSearchTerms",
      valueGetter: (params: ValueGetterParams<ProductTrackerRow>) => {
        if (params.node?.field === "parentAsin")
          return this.dataByParentAsin.get(params.node.key!)?.trackedSearchTerms.length;
        return params.data?.trackedSearchTerms.length;
      },
      type: "numericColumn",
      filter: "agNumberColumnFilter",
    },
    {
      headerName: this.translocoService.translate("product-tracker.main_category", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("product-tracker.main_category"),
      field: "displayGroup",
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => this.displayGroupFormatter(params, false),
      },
      cellRenderer: (params: ICellRendererParams) => this.displayGroupFormatter(params, true),
    },
    ...(this.getProductMetricsDefs() as any),
    {
      field: "price",
      headerName: this.translocoService.translate("common.price", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("common.price"),
      valueFormatter: (params: ValueFormatterParams<ProductTrackerRow>) => {
        const val = params.node?.group ? params.node.aggData.price : params.value;
        return new CurrencyPipe(this.locale!).transform(val, this.currency, "symbol", "1.2-2");
      },
      type: "numericColumn",
      filter: "agNumberColumnFilter",
    },
    {
      field: "reviewScore",
      headerName: this.translocoService.translate("product-tracker.review_score", {}, "en"),
      headerValueGetter: () => this.translocoService.translate("product-tracker.review_score"),
      cellRenderer: ReviewScoreComponent,
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      tooltipField: "reviewScore",
    },
    {
      ...actionColumnProperties<ProductTrackerRow, string>(),
      cellRendererSelector: (params) => {
        if (params.node.group && params.node.field !== "parentAsin") return undefined;
        if (params.node.group && (params.node.key === undefined || params.node.key === "")) return undefined;
        const buttons: ActionButton[] = [];
        if (this.keywordTrackingSupported) {
          buttons.push({
            icon: ICON_LIST,
            tooltip: this.translocoService.translate("product-tracker.tracked_keywords_details"),
            onClick: (params: ICellRendererParams | GetContextMenuItemsParams) => {
              this.navigateToTrackedKeywordsDetails(params.node?.group ? params.node.key : params.node?.data.asin);
            },
          });
        }

        if (!params.node.group) {
          buttons.push({
            icon: ICON_CHART_LINE,
            tooltip: this.translocoService.translate("product-tracker.product_timeline_details"),
            onClick: (params: ICellRendererParams | GetContextMenuItemsParams) => {
              this.navigateToProductTimeline(params.node?.data.asin);
            },
          });
        }

        return {
          component: ActionButtonsComponent,
          params: {
            actionButtons: buttons,
          },
        };
      },
    },
  ];

  basicOpts: GridOptions = getBasicGridOptions(this.PRODUCT_TRACKER_GRID_KEY);
  gridOptions: GridOptions = {
    ...this.basicOpts,
    context: { componentParent: this },
    columnDefs: this.colDefs,
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
    },
    showOpenedGroup: false,
    rowGroupPanelShow: "always",
    enableRangeSelection: false,
    pagination: true,
    onFirstDataRendered: (params: FirstDataRenderedEvent) => {
      if (this.basicOpts.onFirstDataRendered) this.basicOpts.onFirstDataRendered(params);
      params.api.applyColumnState({
        state: [{ colId: "trackedSearchTerms", sort: "desc" }],
        defaultState: { sort: null },
      });

      setTimeout(() => {
        if (params.api.getColumnState().find((c) => c.colId === "parentAsin")?.rowGroup === true) {
          this.isGroupedByParent = true;
        }
        if (this.asinInitValue) {
          params.api.setColumnFilterModel("asin", { type: "equals", filter: this.asinInitValue }).then(() => {
            params.api.onFilterChanged();
            // remove the asin from the url to avoid filtering again on refresh
            this.router.navigate([], {
              queryParams: { [ProductTrackerPageQueryParams.asin]: null },
              queryParamsHandling: "merge",
            });
          });
        }
      });
    },
    isExternalFilterPresent: (_) => this.isSegmentFilterPresent() || this.isCatalogFilterPresent(),
    doesExternalFilterPass: (node: IRowNode<ProductTrackerRow>) =>
      this.segmentFilterPass(node) && this.catalogFilterPass(node),
  };
  gridData: ProductTrackerRow[] = [];

  constructor(
    private accountSelection: AccountSelectionService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private segmentService: SegmentService,
    private keywordTrackerService: KeywordTrackerService,
    private clipboard: Clipboard,
    private toastrService: ToastrService,
    private productTrackerService: ProductTrackerService,
    private keywordTrackingService: KeywordTrackingService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.authService.loggedUser$.pipe(untilDestroyed(this)).subscribe((u: User) => {
      this.locale = u.locale;
    });

    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        tap((am: AccountMarketplace) => {
          this.agGrid?.api.showLoadingOverlay();
          this.loading = true;
          this.accountMarketplace = am;
          this.currency = Marketplaces[am.marketplace].currency;
          const keywordTrackingSupported =
            keywordRankingAvailableFor(am.marketplace) && am.state == AccountState.BIDDER_ON;
          if (keywordTrackingSupported !== this.keywordTrackingSupported) {
            this.keywordTrackingSupported = keywordTrackingSupported;
            this.agGrid?.api.setColumnsVisible(["trackedSearchTerms"], this.keywordTrackingSupported);
            this.agGrid?.api.setColumnsVisible(["reviews"], this.keywordTrackingSupported);
            this.agGrid?.api.setColumnsVisible(["reviewScore"], this.keywordTrackingSupported);
          }
        }),
        switchMap((_) => this.productTrackerService.productTrackerData$),
      )
      .subscribe((data: ProductTrackerData) => {
        this.gridData = data.data;
        this.dataByParentAsin = data.parentData;
        this.loading = false;
      });

    this.accountSelection.singleAccountMarketplaceSelection$
      .pipe(
        untilDestroyed(this),
        switchMap((am: AccountMarketplace) =>
          this.keywordTrackingService.getProductTrackerConfig(am.accountId, am.marketplace),
        ),
      )
      .subscribe((s: ProductTrackerConfig[]) => (this.productTrackerConfig = s));

    combineLatest<[Map<number, SegmentEx>, ParamMap]>([
      this.accountSelection.singleAccountMarketplaceSelection$.pipe(
        switchMap((am) => this.segmentService.getSegments(am.accountId, am.marketplace)),
      ),
      this.route.queryParamMap,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([segmentIndex, params]) => {
        const segments: SegmentEx[] = Array.from(segmentIndex.values()).filter(
          (s) => s.segmentType == SegmentConfigType.ProductSegment,
        );

        this.segmentOptions = segments.map((s) => ({ label: s.name, value: s, segmentId: s.segmentId }));

        const segmentsInParamsIds = params.getAll(ProductTrackerPageQueryParams.segment);
        if (segmentsInParamsIds && segmentsInParamsIds.length > 0) {
          for (const id of segmentsInParamsIds) {
            const segment = segmentIndex.get(parseInt(id));
            if (segment && segment.segmentType === SegmentConfigType.ProductSegment)
              this.selectedSegments.update((s) => [...s, { label: segment.name, value: segment }]);
          }
        }
        if (this.selectedSegments().length > 0) {
          this.segmentFilterChanged(this.selectedSegments());
        }
        this.asinInitValue = params.get(ProductTrackerPageQueryParams.asin)!;
      });
  }

  private displayGroupFormatter = (params: ICellRendererParams | ValueFormatterParams, isLink: boolean) => {
    const displayGroupValue =
      params.node?.field === "parentAsin" ? this.dataByParentAsin.get(params.node!.key!)?.displayGroup : params.value;

    const displayGroup: DisplayGroup | undefined = DisplayGroupsMap.get(
      displayGroupValue + "_" + this.accountMarketplace?.marketplace,
    );

    if (displayGroupValue && !displayGroup) return displayGroupValue; // display group exists as enum for not a formatted value
    if (!displayGroup) return undefined;

    return isLink
      ? `<a href='${displayGroup.displayGroupLink}' target="_blank" class="link">${displayGroup.displayGroupTitle}</a>`
      : displayGroup.displayGroupTitle;
  };

  private getProductMetricsDefs(): ColDef[] {
    const numericCol = { type: "numericColumn", filter: "agNumberColumnFilter" };

    const cols: ColDef[] = [];
    // skip "displayGroup" field (added manually)
    for (const [key, value] of Object.entries(SimpleProductMetrics)) {
      if (value.field === "displayGroup") continue;
      let col: ColDef = {
        headerName: this.translocoService.translate(value.title, {}, "en"),
        headerValueGetter: () => this.translocoService.translate(value.title),
        field: value.field,
        enableRowGroup: !value.numeric,
        filter: !value.numeric ? "agTextColumnFilter" : null,
        valueGetter: (params: ValueGetterParams<ProductTrackerRow>) => {
          if (params.node?.field === "parentAsin" && this.dataByParentAsin.get(params.node.key!))
            return this.dataByParentAsin.get(params.node.key!)![value.field as keyof ProductTrackerRow];
          if (!params.node?.group) return params.data![value.field as keyof ProductTrackerRow];
          return undefined;
        },
      };
      if (value.numeric) col = { ...col, ...numericCol };
      cols.push(col);
    }
    return cols;
  }

  segmentFilterChanged(options: Option<SegmentEx>[]) {
    this.selectedSegments.set(options);
    this.agGrid?.api.onFilterChanged();
  }

  segmentFilterPass(node: IRowNode<ProductTrackerRow>): boolean {
    return (
      !this.selectedSegments() ||
      this.selectedSegments().length === 0 ||
      this.selectedSegments().some((s) => s.value.matchQuery(node.data!.asin!))
    );
  }

  isSegmentFilterPresent(): boolean {
    return this.selectedSegments().length > 0;
  }

  catalogFilterChanged(filter: Option<CatalogFilter>) {
    this.catalogFilterSelected.set(filter);
    this.agGrid?.api.onFilterChanged();
  }

  catalogFilterPass(node: IRowNode<ProductTrackerRow>): boolean {
    const isCatalogProduct = node.data?.inCatalog;
    return (
      this.catalogFilterSelected().value === CatalogFilter.All ||
      (this.catalogFilterSelected().value === CatalogFilter.Catalog && isCatalogProduct) ||
      (this.catalogFilterSelected().value === CatalogFilter.NotInCatalog && !isCatalogProduct)
    );
  }

  isCatalogFilterPresent(): boolean {
    return this.catalogFilterSelected() && this.catalogFilterSelected().value !== CatalogFilter.All;
  }

  exportAsCsv() {
    const colsId = this.agGrid?.api
      ?.getAllDisplayedColumns()
      .map((c) => c.getColId())
      .filter((c) => c !== IMAGE_COL_ID && c !== ACTIONS_COL_ID);
    const fileName = getCsvFileName(
      "product_tracker",
      this.accountMarketplace?.accountGroupName,
      this.accountMarketplace?.marketplace,
    );
    exportGridCsv(this.agGrid?.api, { columnKeys: colsId, fileName: fileName });
  }

  setupGroupBy() {
    const colDefs: ColDef[] = this.agGrid.api.getColumnDefs() as ColDef[];
    colDefs.forEach((c) => {
      if (c.colId === "parentAsin") {
        c.rowGroup = !this.isGroupedByParent;
        c.hide = !this.isGroupedByParent;
      }
    });
    this.isGroupedByParent = !this.isGroupedByParent;
    this.agGrid.api.setGridOption("columnDefs", colDefs);
  }

  openAddProductTrackingModal() {
    this.keywordTrackerService.openAddProductTrackingModal(
      this.accountMarketplace!.accountId,
      this.accountMarketplace!.marketplace,
      this.productTrackerService.getCatalog(),
      this.productTrackerConfig,
    );
  }

  navigateToTrackedKeywordsDetails(product: string) {
    this.router.navigate([this.productTrackerUrl + "/", product]).then();
  }

  navigateToProductTimeline(product: string) {
    this.router.navigate([this.productTrackerUrl + "/product-timeline", product]).then();
  }
}
