import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { IButtonComponent } from "@front/m19-ui";
import { ICON_SEARCH } from "@m19-board/utils/iconsLabels";
import { TranslocoDirective, TranslocoService } from "@jsverse/transloco";

@Component({
  selector: "app-search-dropdown",
  standalone: true,
  imports: [MatTooltipModule, MatMenuModule, MatButtonModule, IButtonComponent, TranslocoDirective],
  templateUrl: "./search-dropdown.component.html",
  styleUrls: ["../gen-dropdown.component.scss"],
})
export class SearchDropdownComponent {
  readonly ICON_SEARCH = ICON_SEARCH;

  input = "";

  @Output() onSearch = new EventEmitter<string>();
  @Input() placeholder = this.translocoService.translate("common.search");
  @Input() title?: string;

  @ViewChild("inputEl") inputEl!: ElementRef;

  constructor(private translocoService: TranslocoService) {}

  search() {
    this.onSearch.emit(this.input);
  }

  setFocus(): void {
    setTimeout(() => {
      this.inputEl?.nativeElement.focus();
    });
  }

  getValueFromInputEvent(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
